import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { UpdateUserApiHandler, logoutUser } from "../utils/Api";
import IsToken from "../utils/Handeler/IsToken";

export default function MyAccount() {
  const navigateTo = useNavigate();

  // Retrieve user details from localStorage
  const user = JSON.parse(localStorage.getItem("user"));
  const token = user ? user.token : null;
  const UserTypeTier = user ? user.user_type_tier : null;
  const DiscountPercentage = user ? user.discount_percentage || 0 : 0;
  // console.log(UserTypeTier);
  // console.log(DiscountPercentage);

  // Initialize state with user details or empty strings
  const [name, setName] = useState(user ? user.name : "");
  const [phonenumber, setPhonenumber] = useState(user ? user.mobile : "");
  const [email, setEmail] = useState(user ? user.email : "");
  const [companyname, setcompanyname] = useState(user ? user.company_name : "");
  const [companyaddress, setcompanyaddress] = useState(
    user ? user.company_address : ""
  );
  const [city, setcity] = useState(user ? user.city : "");
  const [pincode, setpincode] = useState(user ? user.pincode : "");
  const [gstnumber, setgstnumber] = useState(user ? user.gst_number : "");
  const [sizeofeventcompany, setsizeofeventcompany] = useState(
    user ? user.size_of_event_company : ""
  );
  const [state, setstate] = useState(user ? user.state : "");

  // State for managing success and error messages
  const [message, setMessage] = useState(null);
  const [messageType, setMessageType] = useState(""); // 'success' or 'error'

  // State for Password Change
  const [oldPassword, setOldPassword] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [errorMessage, setErrorMessage] = useState({
    oldPassword: "",
    newPassword: "",
  });

  const handleLogout = async () => {
    if (IsToken()) {
      try {
        const data = await logoutUser(token);
        if (data.success) {
          localStorage.removeItem("user");
          sessionStorage.removeItem("user");

          navigateTo("/login");
        } else {
          alert("Logout failed");
        }
      } catch (error) {
        console.error("Logout error:", error);
        alert("An error occurred. Please try again.");
      }
    } else {
      alert("No user is currently logged in.");
    }
  };

  const handleFormSubmit = async (e) => {
    e.preventDefault();

    // Prepare the data as an object (to be passed to PostApiHandler)
    const userData = {
      name,
      mobile: phonenumber,
      email,
      usertype: "event manager",
      company_name: companyname,
      company_address: companyaddress,
      city,
      pincode,
      state,
      gst_number: gstnumber || "", // Optional
      size_of_event_company: sizeofeventcompany,
    };

    try {
      const response = await UpdateUserApiHandler(userData, "update-user");
      if (response.success) {
        setMessageType("success");
        setMessage("Account updated successfully.");
        handleLogout(); // Log the user out after update
        alert("Account updated successfully. Please login to Continue");
      } else {
        setMessageType("error");
        setMessage(response.message || "Failed to update the account.");
        alert(response.message);
      }
    } catch (error) {
      console.error("Form submission failed:", error);
      setMessageType("error");
      setMessage("An error occurred. Please try again later.");
    }
  };

  const handlePasswordChange = async (e) => {
    e.preventDefault();

    // Validate passwords before making the request
    if (!oldPassword) {
      setErrorMessage((prev) => ({
        ...prev,
        oldPassword: "Old password is required",
      }));
      return;
    }
    if (!newPassword) {
      setErrorMessage((prev) => ({
        ...prev,
        newPassword: "New password is required",
      }));
      return;
    }

    // Prepare the data as an object
    const passwordData = {
      old_password: oldPassword,
      new_password: newPassword,
    };

    try {
      const response = await UpdateUserApiHandler(
        passwordData,
        "change-password"
      );
      if (response.success) {
        setMessageType("success");
        setMessage("Password updated successfully.");
        setOldPassword(""); // Clear input fields
        setNewPassword(""); // Clear input fields
        alert("Password updated successfully.");
        handleLogout();
      } else {
        setMessageType("error");
        setMessage(response.message || "Failed to update the password.");
        alert(response.message);
      }
    } catch (error) {
      console.error("Password change failed:", error);
      setMessageType("error");
      setMessage("An error occurred. Please try again later.");
    }
  };

  return (
    <>
      <div className="checkout-area pt-100px pb-100px">
        <div className="container">
          <div className="row">
            <div
              className="col-lg-3 order-lg-first col-md-12 order-md-last mb-md-60px mb-lm-60px"
              style={{ backgroundColor: "#f7f7f7" }}
            >
              <div className="shop-sidebar-wrap">
                <div className="sidebar-widget">
                  <div className="main-heading">
                    <h3 className="sidebar-title">My Account</h3>
                  </div>
                  <div className="sidebar-widget-category">
                    <ul>
                      <li>
                        <Link to="/order-history" className="selected">
                          My Orders
                        </Link>
                      </li>
                      <li>
                        <Link to="/my-account" className="selected">
                          My Account
                        </Link>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>

            <div className="ml-auto mr-auto col-lg-9">
              <div className="checkout-wrapper">
                <div id="faq" className="panel-group">
                  <div
                    className="panel panel-default single-my-account"
                    data-aos="fade-up"
                    data-aos-delay="200"
                  >
                    <div className="panel-heading my-account-title">
                      <h3 className="panel-title">
                        <span>1 .</span>{" "}
                        <Link
                          data-bs-toggle="collapse"
                          className="collapsed"
                          aria-expanded="true"
                          to="#my-account-1"
                        >
                          Edit your account information{" "}
                        </Link>
                      </h3>
                    </div>

                    <div
                      id="my-account-1"
                      className="panel-collapse collapse show"
                      data-bs-parent="#faq"
                    >
                      <div className="panel-body">
                        <div className="myaccount-info-wrapper">
                          {UserTypeTier && <div className="myaccount-user-type">
                          <h5>Tier: {UserTypeTier}</h5>
                          <p>(
                            You get {DiscountPercentage}% discount
                          )
                          </p>
                          </div>}
                          {/* Message Display */}
                          {message && (
                            <div className={`alert alert-${messageType}`}>
                              {message}
                            </div>
                          )}
                          <form onSubmit={handleFormSubmit}>
                            <div className="row">
                              <div className="col-lg-6 col-md-6">
                                <div className="billing-info">
                                  <label>Name</label>
                                  <input
                                    type="text"
                                    value={name}
                                    onChange={(e) => setName(e.target.value)}
                                  />
                                </div>
                              </div>

                              <div className="col-lg-6 col-md-6">
                                <div className="billing-info">
                                  <label>Mobile Number</label>
                                  <input
                                    type="text"
                                    value={phonenumber}
                                    onChange={(e) =>
                                      setPhonenumber(e.target.value)
                                    }
                                  />
                                </div>
                              </div>

                              <div className="col-lg-6 col-md-6">
                                <div className="billing-info">
                                  <label>Email Address</label>
                                  <input
                                    type="email"
                                    value={email}
                                    onChange={(e) => setEmail(e.target.value)}
                                  />
                                </div>
                              </div>

                              <div className="col-lg-6 col-md-6">
                                <div className="billing-info">
                                  <label>Company Name</label>
                                  <input
                                    type="text"
                                    value={companyname}
                                    onChange={(e) =>
                                      setcompanyname(e.target.value)
                                    }
                                  />
                                </div>
                              </div>

                              <div className="col-lg-6 col-md-6">
                                <div className="billing-info">
                                  <label>City</label>
                                  <input
                                    type="text"
                                    value={city}
                                    onChange={(e) => setcity(e.target.value)}
                                  />
                                </div>
                              </div>

                              <div className="col-lg-6 col-md-6">
                                <div className="billing-info">
                                  <label>Pincode</label>
                                  <input
                                    type="text"
                                    value={pincode}
                                    onChange={(e) => setpincode(e.target.value)}
                                  />
                                </div>
                              </div>

                              <div className="col-lg-6 col-md-6">
                                <div className="billing-info">
                                  <label>GST Number</label>
                                  <input
                                    type="text"
                                    value={gstnumber}
                                    onChange={(e) =>
                                      setgstnumber(e.target.value)
                                    }
                                  />
                                </div>
                              </div>

                              <div className="col-lg-6 col-md-6">
                                <div className="billing-info">
                                  <label>Size of Event Company</label>
                                  <select
                                    className="form-control"
                                    value={sizeofeventcompany}
                                    onChange={(e) =>
                                      setsizeofeventcompany(e.target.value)
                                    }
                                  >
                                    <option value="">
                                      Select Size of the company
                                    </option>
                                    <option value="small">Small</option>
                                    <option value="medium">Medium</option>
                                    <option value="large">Large</option>
                                  </select>
                                </div>
                              </div>

                              <div className="col-lg-12 col-md-12">
                                <div className="billing-info">
                                  <label>Company Address</label>
                                  <input
                                    type="text"
                                    value={companyaddress}
                                    onChange={(e) =>
                                      setcompanyaddress(e.target.value)
                                    }
                                  />
                                </div>
                              </div>
                            </div>
                            <div className="billing-back-btn">
                              <div className="billing-btn">
                                <button type="submit">Update</button>
                              </div>
                            </div>
                          </form>
                        </div>
                      </div>
                    </div>
                  </div>
                  {/* Additional sections for changing password or other actions */}

                  <div
                    className="panel panel-default single-my-account"
                    data-aos="fade-up"
                    data-aos-delay="400"
                  >
                    <div className="panel-heading my-account-title">
                      <h3 className="panel-title">
                        <span>2 .</span>{" "}
                        <Link
                          data-bs-toggle="collapse"
                          className="collapsed"
                          aria-expanded="false"
                          to="#my-account-2"
                        >
                          Change your password{" "}
                        </Link>
                      </h3>
                    </div>
                    <div
                      id="my-account-2"
                      className="panel-collapse collapse"
                      data-bs-parent="#faq"
                    >
                      <div className="panel-body">
                        <div className="myaccount-info-wrapper">
                          <form onSubmit={handlePasswordChange}>
                            <div className="row">
                              <div className="col-lg-12 col-md-12">
                                <div className="billing-info">
                                  <label>Old Password</label>
                                  <input
                                    type="password"
                                    value={oldPassword}
                                    onChange={(e) =>
                                      setOldPassword(e.target.value)
                                    }
                                  />
                                  {errorMessage.oldPassword && (
                                    <span className="error">
                                      {errorMessage.oldPassword}
                                    </span>
                                  )}
                                </div>
                              </div>
                              <div className="col-lg-12 col-md-12">
                                <div className="billing-info">
                                  <label>New Password</label>
                                  <input
                                    type="password"
                                    value={newPassword}
                                    onChange={(e) =>
                                      setNewPassword(e.target.value)
                                    }
                                  />
                                  {errorMessage.newPassword && (
                                    <span className="error">
                                      {errorMessage.newPassword}
                                    </span>
                                  )}
                                </div>
                              </div>
                            </div>
                            <div className="billing-back-btn">
                              <div className="billing-btn">
                                <button type="submit">Update Password</button>
                              </div>
                            </div>
                          </form>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
