import React, { useEffect, useRef } from "react";
import { Link } from "react-router-dom";
import cat1 from "../../assets/images/icons/1.png";
import cat2 from "../../assets/images/icons/2.png";
import cat3 from "../../assets/images/icons/3.png";
import cat4 from "../../assets/images/icons/4.png";
import cat5 from "../../assets/images/icons/5.png";
import Swiper from "swiper/bundle";
import "swiper/css/bundle";

const CategorySlider = () => {
  const swiperRefs = useRef(null);

  useEffect(() => {
    // Initialize Swiper when component mounts
    swiperRefs.current = new Swiper(".category-slider", {
      slidesPerView: 5, // Adjust as needed
      spaceBetween: 30,
      loop: true,
      autoplay: {
        delay: 3000,
        disableOnInteraction: false,
      },
      pagination: {
        el: ".swiper-pagination",
        clickable: true,
      },
      navigation: {
        nextEl: ".swiper-button-next",
        prevEl: ".swiper-button-prev",
      },
      breakpoints: {
        240: {
            slidesPerView: 2,
          },
        640: {
          slidesPerView: 2,
        },
        768: {
          slidesPerView: 3,
        },
        1024: {
          slidesPerView: 4,
        },
        1200: {
          slidesPerView: 5,
        },
      },
    });

    // Cleanup on unmount
    return () => {
      if (swiperRefs.current) {
        swiperRefs.current.destroy();
      }
    };
  }, []);

  return (
    <div className="section pt-100px pb-100px">
      <div className="container">
        <div className="category-slider swiper-container" data-aos="fade-up">
          <div className="category-wrapper swiper-wrapper">
            <div className="swiper-slide">
              <Link to="/" className="category-inner">
                <div className="category-single-item">
                  <img src={cat1} alt="" />
                  <span className="title">Office Chair</span>
                </div>
              </Link>
            </div>
            <div className="swiper-slide">
              <Link to="/" className="category-inner">
                <div className="category-single-item">
                  <img src={cat2} alt="" />
                  <span className="title">Book Shelf</span>
                </div>
              </Link>
            </div>
            <div className="swiper-slide">
              <Link to="/" className="category-inner">
                <div className="category-single-item">
                  <img src={cat3} alt="" />
                  <span className="title">Light & Sofa</span>
                </div>
              </Link>
            </div>
            <div className="swiper-slide">
              <Link to="/" className="category-inner">
                <div className="category-single-item">
                  <img src={cat4} alt="" />
                  <span className="title">Reading Table</span>
                </div>
              </Link>
            </div>
            <div className="swiper-slide">
              <Link to="/" className="category-inner">
                <div className="category-single-item">
                  <img src={cat5} alt="" />
                  <span className="title">Corner Table</span>
                </div>
              </Link>
            </div>
            <div className="swiper-slide">
              <Link to="/" className="category-inner">
                <div className="category-single-item">
                  <img src={cat5} alt="" />
                  <span className="title">Corner Table</span>
                </div>
              </Link>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CategorySlider;
