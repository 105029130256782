export default function IsToken() {
  const user = JSON.parse(localStorage.getItem("user"));
//   const userType = user ? user.usertype : null;
  const token = user ? user.token : null;
  if (token && token !== null && token !== undefined && token !== "") {
    return token;
  } else {
    return false;
  }
}
