import React, { useEffect, useState } from "react";
import { GetApiHandlerWithoutParams } from "../../utils/Api";

export default function LocationDateFilterModal({
  show,
  onClose,
  onSubmit, // Add this to receive the callback from parent
}) {
  const [locations, setLocations] = useState([]);
  const [selectedLocation, setSelectedLocation] = useState({
    id: "",
    name: "",
  });
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [errorMessage, setErrorMessage] = useState({
    locations: "",
    dates: "",
  });

  const today = new Date().toISOString().split("T")[0];

  useEffect(() => {
    if (show) {
      locationsApiCall(); // Fetch locations when the modal is shown
    }
  }, [show]);

  const locationsApiCall = async () => {
    try {
      const endPoint = `location`;
      const result = await GetApiHandlerWithoutParams(endPoint, "");
      if (result && result.success && result.addresses) {
        const formattedData = result.addresses.map((location) => ({
          value: location.id,
          label: location.name,
        }));
        setLocations(formattedData);
      }
    } catch (err) {
      console.error("Failed to fetch locations:", err.message);
    }
  };

  const handleLocationChange = (event) => {
    const selectedIndex = event.target.selectedIndex;
    const locationName = event.target.options[selectedIndex].text;

    setSelectedLocation({
      id: event.target.value,
      name: locationName,
    });
  };

  const handleLocationDateSubmit = () => {
    let valid = true;
    let errors = { locations: "", dates: "" };

    if (!selectedLocation.id) {
      errors.locations = "Please select a location.";
      valid = false;
    }

    if (!startDate || !endDate) {
      errors.dates = "Please select both start and end dates.";
      valid = false;
    }

    setErrorMessage(errors);

    if (valid) {
      const formData = {
        locationId: selectedLocation.id,
        locationName: selectedLocation.name,
        startDate,
        endDate,
      };

      localStorage.setItem("locationDateFilter", JSON.stringify(formData));

      // Pass the data back to the parent component using the onSubmit callback
      onSubmit(formData);

      onClose(); // Close the modal
    }
  };

  if (!show) return null;

  return (
    <div
      className="modal fade show"
      id="exampleModal"
      role="dialog"
      style={{ display: "block" }}
    >
      <div className="modal-dialog" role="document">
        <div className="modal-content">
          <div className="modal-header">
            <p>Please Select Location and Date</p>
          </div>
          <div className="modal-body">
            <div className="row">
              <div className="col-md-12">
                <div className="billing-info-wrap">
                  <div className="row">
                    <div className="col-lg-12">
                      <div className="billing-select mb-20px">
                        <label>Location</label>
                        <select
                          className="form-control"
                          value={selectedLocation.id}
                          onChange={handleLocationChange}
                        >
                          <option value="">Select a location</option>
                          {locations.map((location) => (
                            <option key={location.value} value={location.value}>
                              {location.label}
                            </option>
                          ))}
                        </select>
                        {errorMessage.locations && (
                          <p style={{ color: "red" }}>
                            {errorMessage.locations}
                          </p>
                        )}
                      </div>
                    </div>

                    <div className="col-lg-12">
                      <div className="billing-select mb-20px">
                        <label>Rental Start Date</label>
                        <input
                          type="date"
                          className="form-control"
                          value={startDate}
                          onChange={(e) => setStartDate(e.target.value)}
                          min={today}
                        />
                      </div>
                    </div>

                    <div className="col-lg-12">
                      <div className="billing-select mb-20px">
                        <label>Rental End Date</label>
                        <input
                          type="date"
                          className="form-control"
                          value={endDate}
                          onChange={(e) => setEndDate(e.target.value)}
                          min={startDate || today}
                        />
                      </div>
                    </div>

                    {errorMessage.dates && (
                      <div className="col-lg-12">
                        <p style={{ color: "red" }}>{errorMessage.dates}</p>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="modal-footer">
            <button
              type="button"
              className="btn btn-primary"
              onClick={handleLocationDateSubmit}
            >
              Submit
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}
