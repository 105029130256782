import React, { useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import Cart from "./Cart";

export default function OrderSuccess() {
  const { state } = useLocation();
  const orderData = state?.orderData || {}; // Safely handle undefined state
  const navigate = useNavigate();

  useEffect(() => {
    const handleBackButton = (event) => {
      event.preventDefault();
      navigate("/", { replace: true }); // Replace history with home
    };

    // Listen for back button press on mobile or browser
    window.history.pushState(null, null, window.location.href);
    window.addEventListener("popstate", handleBackButton);

    // Clean up the event listener on component unmount
    return () => {
      window.removeEventListener("popstate", handleBackButton);
    };
  }, [navigate]);

  // If no orderData is found, redirect to home or show a message
  if (!orderData || !orderData.id) {
    return (
      <Cart/>
    );
  }

  const {
    rentalstartdate,
    rentalenddate,
    returndate,
    grand_total,
    additional_request,
    event_venue,
    event_type,
    delivery_address,
    pincode,
    city,
    state: orderState,
    country,
    products,
    id,
    discount_percentage = 0,
  } = orderData;

  // Format dates
  const formatDate = (dateString) => {
    const date = new Date(dateString);
    const options = { day: "numeric", month: "short", year: "numeric" };
    return date.toLocaleDateString("en-GB", options);
  };

   // Calculate Discount
   const discountAmount = (grand_total * discount_percentage) / 100;
   const totalBeforeDiscount = grand_total + discountAmount; // Total before discount
   const totalAfterDiscount = grand_total; // Total after discount

  return (
    <div className="checkout-area pt-100px pb-100px">
      <div className="container">
        <div className="row">
          <div className="col-lg-12">
            <div className="order-success-container">
              <div className="order-success-message">
                <h3>Thank you. Your order has been received.</h3>
              </div>

              <div className="order-summary">
                <div className="order-info">
                  <div className="order-info-item">
                    <strong>Order number:</strong> #{id}
                  </div>

                  <div className="order-info-item">
                    <strong>Total:</strong> ₹{grand_total}
                  </div>
                </div>
              </div>
              <div className="order-summary">
                <div className="order-info1">
                  <div className="order-info-item">
                    <strong>Start Date:</strong> {formatDate(rentalstartdate)}
                  </div>
                  <div className="order-info-item">
                    <strong>End Date:</strong> {formatDate(rentalenddate)}
                  </div>
                  <div className="order-info-item">
                    <strong>Return Date:</strong> {formatDate(returndate)}
                  </div>
                </div>
              </div>

              <div className="order-details">
                <h4>Order Details</h4>
                <div className="order-details-products">
                  <table className="order-products-table">
                    <thead>
                      <tr>
                        <th>Product</th>
                        <th>Total</th>
                      </tr>
                    </thead>
                    <tbody>
                      {products.map((product) => (
                        <tr key={product.product_id}>
                          <td>
                            {product.product_name} x {product.quantity}
                          </td>
                          <td>₹{product.price * product.quantity}</td>
                        </tr>
                      ))}

                      <tr>
                        <td>
                          <strong>Total Amount (After Discount):</strong>
                        </td>
                        <td>
                          <strong>₹{grand_total}</strong>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>

                <div className="order-extra-info">
                  <p>
                    <strong>Event Venue:</strong> {event_venue}
                  </p>
                  <p>
                    <strong>Event Type:</strong> {event_type}
                  </p>
                  <p>
                    <strong>Delivery Address:</strong> {delivery_address},{" "}
                    {city}, {orderState}, {country}
                  </p>
                  <p>
                    <strong>Pincode:</strong> {pincode}
                  </p>
                  <p>
                    <strong>Additional Request:</strong>{" "}
                    {additional_request || "None"}
                  </p>
                </div>
              </div>

              <button className="btnordersuccess" onClick={() => navigate("/")}>
                Continue Shopping
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
