import React from "react";
import { Navigate, useLocation } from "react-router-dom";
import IsToken from "../utils/Handeler/IsToken";
import Login from "../Screens/Login";

// function PrivateRoute({ children }) {
//   // const user = JSON.parse(localStorage.getItem("user"));
//   // const token = user ? user.token : null;

//   return IsToken() ? children : <Login/>;
// }

// export default PrivateRoute;

function PrivateRoute({ children }) {
  const location = useLocation();
  
  // Capture the user's intended route
  return IsToken() ? (
    children
  ) : (
    <Navigate to="/login" state={{ from: location }} />
  );
}

export default PrivateRoute;

{/* <Navigate to="/login" /> */}
