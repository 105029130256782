import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { PostApiHandler } from "../utils/Api";
import { Circles } from "react-loader-spinner";
import CustomToast from "../Components/CustomToast";


export default function OrderDetails() {
  const { id } = useParams(); // Get the order ID from the URL
  const [orderDetails, setOrderDetails] = useState(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    fetchOrderDetails(id);
  }, [id]);

  // Fetch order details using a POST request
  const fetchOrderDetails = async (orderId) => {
    try {
      const endPoint = `orders/${orderId}`; // The API endpoint
      const result = await PostApiHandler({ id: orderId }, endPoint); // Make POST request with order ID in body
      if (result && result.success) {
        setOrderDetails(result.orderItem); // Set order details
      } else {
        CustomToast(result.message, "error");
      }
    } catch (error) {
      console.error("Error fetching order details:", error.message);
      CustomToast("Failed to load order details", "error");
    } finally {
      setLoading(false); // Stop loading
    }
  };

  // Format dates
  const formatDate = (dateString) => {
    const date = new Date(dateString);
    const options = { day: "numeric", month: "short", year: "numeric" };
    return date.toLocaleDateString("en-GB", options);
  };

  // Remove HTML tags from description
  const stripHtml = (html) => {
    return html.replace(/<\/?[^>]+(>|$)/g, "");
  };

  return (
    <>
      {loading ? (
        <div className="loading-overlay">
          <Circles
            height="80"
            width="80"
            color="#3498db"
            ariaLabel="loading"
            wrapperStyle={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              height: "100vh",
              position: "fixed",
              top: 0,
              left: 0,
              right: 0,
              bottom: 0,
              zIndex: 1000,
              backgroundColor: "rgba(255, 255, 255, 0.8)",
            }}
          />
        </div>
      ) : (
        <div className="order-details-container container pt-100px pb-100px">
          {orderDetails ? (
            <div className="order-details-content">
              <h3 className="order-id">Order #{orderDetails.id}</h3>

              <div className="order-info">
                <div className="order-info-item">
                  <strong>Event Venue:</strong> {orderDetails.event_venue}
                </div>
                <div className="order-info-item">
                  <strong>Rental Start Date:</strong>{" "}
                  {formatDate(orderDetails.rentalstartdate)}
                </div>
                <div className="order-info-item">
                  <strong>Rental End Date:</strong>{" "}
                  {formatDate(orderDetails.rentalenddate)}
                </div>
                <div className="order-info-item">
                  <strong>Return Date:</strong>{" "}
                  {formatDate(orderDetails.returndate)}
                </div>
                <div className="order-info-item">
                  <strong>City:</strong> {orderDetails.city}
                </div>
                <div className="order-info-item">
                  <strong>State:</strong> {orderDetails.state}
                </div>
                <div className="order-info-item">
                  <strong>Country:</strong> {orderDetails.country}
                </div>
                <div className="order-info-item">
                  <strong>Status:</strong>{" "}
                  {orderDetails.order_status
                    ? orderDetails.order_status.status
                    : "Unknown"}
                </div>
                <div className="order-info-item">
                  <strong>Grand Total:</strong> ₹
                  {parseFloat(orderDetails.grand_total).toFixed(2)}
                </div>
              </div>

              <h4 className="returned-items-title">Order Items:</h4>

              <div className="returned-items">
                {orderDetails.cart.length > 0 ? (
                  <div className="returned-items-list">
                    {orderDetails.cart.map((item) => (
                      <div key={item.id} className="returned-item">
                        <div className="returned-item-image">
                          <img
                            src={
                              item.product.image_1 ||
                              "https://via.placeholder.com/150"
                            }
                            alt={item.product.product_name}
                          />
                        </div>
                        <div className="returned-item-details">
                          <p>
                            <strong>Product Name:</strong>{" "}
                            {item.product.product_name}
                          </p>
                          <p>
                            <strong>Description:</strong>{" "}
                            {stripHtml(item.product.description)}
                          </p>
                          
                          <p>
                            <strong>Rental Price:</strong> ₹
                            {parseFloat(item.product.rental_price).toFixed(2)}
                          </p>
                        </div>
                      </div>
                    ))}
                  </div>
                ) : (
                  <p>No returned items found.</p>
                )}
              </div>
            </div>
          ) : (
            <p>Order details not found.</p>
          )}
        </div>
      )}
    </>
  );
}
