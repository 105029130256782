import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { GetOrdersApiHandler, PostApiHandler } from "../utils/Api";
import IsToken from "../utils/Handeler/IsToken";
import CustomToast from "../Components/CustomToast";
import { toast } from "react-toastify";
import { Circles } from "react-loader-spinner";
import "../assets/css/orderhistory.css";

export default function Orderhistory() {
  const navigateTo = useNavigate();

  const [orders, setOrders] = useState([]);
  const [currentPage, setCurrentPage] = useState(1); // Current page state
  const [totalPages, setTotalPages] = useState(1); // Total number of pages
  const [loading, setLoading] = useState(false); // Loading state
  const [orderStatus, setOrderStatus] = useState(""); // State for order status filter
  const [orderIdSearch, setOrderIdSearch] = useState(""); // State for order ID search
  const limit = 10; // Number of orders per page

  // Retrieve user details from localStorage
  const user = JSON.parse(localStorage.getItem("user"));
  const token = user ? user.token : null;

  // If no token, navigate to login page
  useEffect(() => {
    if (!IsToken()) {
      navigateTo("/login");
    } else {
      fetchOrderHistory(currentPage, orderStatus, orderIdSearch); // Call API for the current page
    }
  }, [currentPage, orderStatus]); // Update when currentPage or orderStatus changes

  // Fetch order history with pagination, order_status, and order_id filters
  const fetchOrderHistory = async (page, status, orderId) => {
    try {
      setLoading(true); // Start loading
      let endPoint = `orders?limit=${limit}&page=${page}`;

      // Add order_status and order_id to the API call if they are set
      if (status) {
        endPoint += `&order_status=${status}`;
      }
      if (orderId) {
        endPoint += `&order_id=${orderId}`;
      }

      const result = await GetOrdersApiHandler({}, endPoint);

      if (result && result.success) {
        setOrders(result.orders.data); // Update orders
        setTotalPages(result.orders.last_page || 1); // Update total pages
      } else {
        console.error(result.message);
        CustomToast("Failed to load orders", "error");
      }
    } catch (err) {
      console.error("Error fetching order history:", err.message);
      CustomToast("Error fetching order history", "error");
    } finally {
      setLoading(false); // Stop loading
    }
  };

  // Handle page change
  const handlePageChange = (newPage) => {
    if (newPage >= 1 && newPage <= totalPages) {
      setCurrentPage(newPage); // Update the current page
    }
  };

  // Handle order_status change
  const handleStatusChange = (e) => {
    setOrderStatus(e.target.value); // Update order status filter
  };

  // Handle search by order ID
  const handleOrderIdSearch = () => {
    fetchOrderHistory(currentPage, orderStatus, orderIdSearch); // Fetch orders based on order ID
  };

  // Cancel Order Confirmation
  const confirmCancelOrder = (orderId) => {
    toast(
      ({ closeToast }) => (
        <div>
          <p>Do you want to Cancel This Order?</p>
          <div className="d-flex justify-content-between">
            <button
              className="btn btn-success btn-sm"
              onClick={() => {
                handleCancelOrder(orderId);
                closeToast(); // Close toast on confirmation
              }}
            >
              Yes
            </button>
            <button
              className="btn btn-danger btn-sm"
              onClick={() => closeToast()} // Close toast if cancelled
            >
              No
            </button>
          </div>
        </div>
      ),
      {
        autoClose: false, // Disable auto close to allow user interaction
        closeOnClick: false, // Prevent toast from closing on click outside
        position: "top-center",
      }
    );
  };

  // Handle Cancel Order
  const handleCancelOrder = async (orderId) => {
    try {
      const result = await PostApiHandler({}, `orders/cancel-order/${orderId}`);
      if (result.success) {
        setOrders((prevOrders) =>
          prevOrders.map((order) =>
            order.id === orderId
              ? { ...order, order_status: { status: "cancelled" } }
              : order
          )
        );
        CustomToast("Order cancelled successfully.", "success");
      } else {
        throw new Error(result.message);
      }
    } catch (error) {
      console.error(`Failed to cancel order ${orderId}:`, error.message);
      CustomToast("Failed to cancel the order.", "error");
    }
  };

  // Format dates
  const formatDate = (dateString) => {
    const date = new Date(dateString);
    const options = { day: "numeric", month: "short", year: "numeric" };
    return date.toLocaleDateString("en-GB", options);
  };

  // Handle reset of filters
  const handleReset = () => {
    setOrderStatus(""); // Reset order status
    setOrderIdSearch(""); // Reset order ID search
    fetchOrderHistory(1, "", ""); // Fetch all orders without any filters
  };

  return (
    <>
      {/* Loader */}
      {loading && (
        <div className="loading-overlay">
          <Circles
            height="80"
            width="80"
            color="#3498db"
            ariaLabel="loading"
            wrapperStyle={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              height: "100vh",
              position: "fixed",
              top: 0,
              left: 0,
              right: 0,
              bottom: 0,
              zIndex: 1000,
              backgroundColor: "rgba(255, 255, 255, 0.8)",
            }}
          />
        </div>
      )}

      {/* Main content */}
      {!loading && (
        <div className="checkout-area pt-100px pb-100px">
          <div className="container">
            <div className="row">
              <div
                className="col-lg-3 order-lg-first col-md-12 order-md-last mb-md-60px mb-lm-60px"
                style={{ backgroundColor: "#f7f7f7" }}
              >
                <div className="shop-sidebar-wrap">
                  <div className="sidebar-widget">
                    <div className="main-heading">
                      <h3 className="sidebar-title">My Account</h3>
                    </div>
                    <div className="sidebar-widget-category">
                      <ul>
                        <li>
                          <Link to="/order-history" className="selected">
                            Order Details
                          </Link>
                        </li>
                        <li>
                          <Link to="/my-account" className="selected">
                            My Account
                          </Link>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>

              <div className="ml-auto mr-auto col-lg-9">
                <div className="checkout-wrapper">
                  <div id="faq" className="panel-group">
                    <div
                      className="panel panel-default single-my-account"
                      data-aos="fade-up"
                      data-aos-delay="200"
                    >
                      <div className="panel-heading my-account-title"></div>

                      {/* Search and Filters */}
                      <div className="row mb-4">
                        <div className="col-lg-4 col-md-6 mb-3">
                          <label htmlFor="order-status" className="form-label">
                            Filter by Order Status
                          </label>
                          <select
                            id="order-status"
                            value={orderStatus}
                            onChange={handleStatusChange}
                            className="form-control"
                          >
                            <option value="">All Statuses</option>
                            <option value="1">New Order</option>
                            <option value="2">Processing</option>
                            <option value="3">Delivered</option>
                            <option value="4">Cancelled</option>
                          </select>
                        </div>

                        <div className="col-lg-4 col-md-6 mb-3">
                          <label
                            htmlFor="order-id-search"
                            className="form-label"
                          >
                            Search by Order ID
                          </label>
                          <input
                            type="text"
                            id="order-id-search"
                            value={orderIdSearch}
                            onChange={(e) => setOrderIdSearch(e.target.value)}
                            className="form-control"
                            placeholder="Enter Order ID"
                          />
                        </div>

                        <div className="col-lg-4 col-md-12 d-flex align-items-center">
                          <button
                            className="btn btn-primary mr-2"
                            onClick={handleOrderIdSearch}
                          >
                            Search
                          </button>
                          <button
                            className="btn btn-secondary"
                            onClick={handleReset}
                          >
                            Reset
                          </button>
                        </div>
                      </div>

                      {/* Render orders */}
                      <div className="panel-body">
                        {orders.length > 0 ? (
                          <table className="tableorderhistory table-striped table-responsive-md">
                            <thead>
                              <tr>
                                <th>Order ID</th>
                                <th>Rental Start Date</th>
                                <th>Rental End Date</th>
                                <th>Grand Total</th>
                                <th>Status</th>
                                <th>Action</th>
                              </tr>
                            </thead>
                            <tbody>
                              {orders.map((order) => (
                                <tr key={order.id}>
                                  <td data-label="Order ID">#{order.id}</td>
                                  <td data-label="Rental Start Date">
                                    {formatDate(order.rentalstartdate)}
                                  </td>
                                  <td data-label="Rental End Date">
                                    {formatDate(order.rentalenddate)}
                                  </td>
                                  <td data-label="Grand Total">
                                    ₹
                                    {parseFloat(order.grand_total)
                                      .toFixed(2)
                                      .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                                  </td>
                                  <td data-label="Status">
                                    {order.order_status.status}
                                  </td>
                                  <td data-label="Action">
                                    {order.order_status.order_type ===
                                      "new_order" && (
                                      <button
                                        className="cancel-btn"
                                        onClick={() =>
                                          confirmCancelOrder(order.id)
                                        }
                                      >
                                        Cancel
                                      </button>
                                    )}
                                    <button
                                      className="view-order-btn"
                                      onClick={() =>
                                        navigateTo(`/order-details/${order.id}`)
                                      }
                                    >
                                      View Order
                                    </button>
                                  </td>
                                </tr>
                              ))}
                            </tbody>
                          </table>
                        ) : (
                          <p>No orders found.</p>
                        )}
                      </div>
                    </div>

                    {/* Pagination Area Start */}
                    <div className="pro-pagination-style text-center mb-md-30px mb-lm-30px mt-30px">
                      <ul className="pagination">
                        <li>
                          <Link
                            className={`prev ${
                              currentPage === 1 ? "disabled" : ""
                            }`}
                            onClick={() => handlePageChange(currentPage - 1)}
                            disabled={currentPage === 1}
                          >
                            <i className="ion-ios-arrow-left"></i>
                          </Link>
                        </li>
                        {Array.from({ length: totalPages }, (_, index) => (
                          <li key={index + 1}>
                            <Link
                              className={`page-link ${
                                currentPage === index + 1 ? "active" : ""
                              }`}
                              onClick={() => {
                                handlePageChange(index + 1);
                              }}
                            >
                              {index + 1}
                            </Link>
                          </li>
                        ))}
                        <li>
                          <Link
                            className={`next ${
                              currentPage === totalPages ? "disabled" : ""
                            }`}
                            onClick={() => handlePageChange(currentPage + 1)}
                            disabled={currentPage === totalPages}
                          >
                            <i className="ion-ios-arrow-right"></i>
                          </Link>
                        </li>
                      </ul>
                    </div>
                    {/* Pagination Area End */}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
}
