import React, { useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom"; // Import useNavigate
import { GetApiHandlerWithoutParams, GetApiHandler } from "../utils/Api";
import p1 from "../assets/images/product-image/1.jpg";
import IsToken from "../utils/Handeler/IsToken";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css"; 

export default function Shop() {
  const [sortCriteria, setSortCriteria] = useState("Relevance");
  const [products, setProducts] = useState([]);
  const [locations, setLocations] = useState([]); // State for available locations
  const [selectedLocation, setSelectedLocation] = useState({
    id: "",
    name: "",
  });
  const [startDate, setStartDate] = useState(null); // State for rental start date
  const [endDate, setEndDate] = useState(null); // State for rental end date

  const navigate = useNavigate(); // Hook for navigation

  // Fetch inventories and locations when component mounts or filters change
  useEffect(() => {
    locationsApiCall(); // Fetch locations initially
  }, []);

  // Re-fetch the inventories when filters are applied
  useEffect(() => {
    inventoriesApiCall(); // Fetch inventories based on filters
  }, [selectedLocation, startDate, endDate]);

  const locationsApiCall = async () => {
    try {
      const endPoint = `location`;
      const result = await GetApiHandlerWithoutParams(endPoint, "");
      console.log("Location", result);
      if (result && result.success && result.addresses) {
        const formattedData = result.addresses.map((location) => ({
          value: location.id,
          label: location.name,
        }));
        setLocations(formattedData); // Update the available locations
      }
    } catch (err) {
      console.error("Failed to fetch locations:", err.message);
    }
  };

  const inventoriesApiCall = async () => {
    try {
      const params = {
        location_id: selectedLocation.id, // Send selected location ID as a parameter
        start_date: startDate ? startDate.toISOString().split("T")[0] : null,
        end_date: endDate ? endDate.toISOString().split("T")[0] : null,
      };
      const endPoint = `inventories`;
      const result = await GetApiHandler(params, endPoint);
      console.log("result", result);

      if (
        result &&
        result.success &&
        result.inventories &&
        result.inventories.data
      ) {
        // Map through the API response and format the products
        const fetchedProducts = result.inventories.data.map((inventory) => ({
          id: inventory.id,
          name: inventory.product.product_name,
          price: inventory.product.rental_price,
          image: inventory.product.image_1 || p1, // Fallback to p1 if image is not available
          badge: inventory.location.name, // Location as badge (e.g., "Kolkata" or "Delhi")
          off: inventory.product.discount_percentage,
          link: `/product/${inventory.product.id}`, // Dynamic link to the product
        }));

        setProducts(fetchedProducts); // Update state with fetched products
      }
    } catch (err) {
      console.error("Failed to fetch inventories:", err.message);
    }
  };

  const handleLocationChange = (event) => {
    const selectedIndex = event.target.selectedIndex;
    const locationName = event.target.options[selectedIndex].text;

    setSelectedLocation({
      id: event.target.value,
      name: locationName,
    });
  };

  // const handleSortChange = (event) => {
  //   const selectedSort = event.target.value;
  //   setSortCriteria(selectedSort);

  //   let sortedProducts = [...products];

  //   switch (selectedSort) {
  //     case "name-asc":
  //       sortedProducts.sort((a, b) => a.name.localeCompare(b.name));
  //       break;
  //     case "name-desc":
  //       sortedProducts.sort((a, b) => b.name.localeCompare(a.name));
  //       break;
  //     case "price-asc":
  //       sortedProducts.sort((a, b) => a.price - b.price);
  //       break;
  //     case "price-desc":
  //       sortedProducts.sort((a, b) => b.price - a.price);
  //       break;
  //     default:
  //       sortedProducts = products; // Reset to current state
  //       break;
  //   }

  //   setProducts(sortedProducts);
  // };

  const handleProductClick = (id) => {
    // Navigate to the SingleProduct component and pass the product id in the URL
    navigate(`/product/${id}`);
  };

  return (
    <>
      <div className="shop-category-area pb-100px pt-70px">
        <div className="container">
          <div className="row">
            <div className="col-lg-12 col-md-12">
              {/* Shop Top Filter Area Start */}
              <div className="shop-top-bar d-flex align-items-center justify-content-between" style={{backgroundColor:"#fff"}}>
                {/* Filter Section */}
                <div className="filter-section d-flex align-items-center gap-3">
                  <div className="location-filter">
                  <p className="date-label">Select Location:</p>
                    <select
                      className="location-select"
                      value={selectedLocation.id}
                      onChange={handleLocationChange}
                    >
                      <option value="">Select Location</option>
                      {locations.map((loc) => (
                        <option key={loc.value} value={loc.value}>
                          {loc.label}
                        </option>
                      ))}
                    </select>
                  </div>
                  
                  {/* Rental Start and End Date Filters */}
                  <div className="location-filter" style={{zIndex:9}}>
                    <p className="date-label">Rental Start Date:</p>
                    <DatePicker
                      selected={startDate}
                      onChange={(date) => setStartDate(date)}
                      dateFormat="yyyy-MM-dd"
                      placeholderText="Select start date"
                      className="date-select"
                    />
                    </div>
                    <div className="location-filter" style={{zIndex:9}}>
                    <p className="date-label">Rental End Date:</p>
                    <DatePicker
                      selected={endDate}
                      onChange={(date) => setEndDate(date)}
                      dateFormat="yyyy-MM-dd"
                      placeholderText="Select end date"
                      className="date-select"
                    />
                  </div>


                  <button
                    onClick={inventoriesApiCall}
                    className="btn btn-primary apply-filters-btn"
                  >
                    Apply Filters
                  </button>
                </div>
              </div>
              {/* Shop Top Filter Area End  */}

              {/* Shop Top Area Start */}
              <div className="shop-top-bar d-flex">
                <p>Showing {products.length} Products.</p>

                {/* <div className="select-shoing-wrap d-flex align-items-center">
                  <div className="shot-product">
                    <p>Sort By:</p>
                  </div>
                  <div className="shop-select">
                    <select
                      className="shop-sort"
                      value={sortCriteria}
                      onChange={handleSortChange}
                    >
                      <option value="Relevance">Relevance</option>
                      <option value="name-asc">Name, A to Z</option>
                      <option value="name-desc">Name, Z to A</option>
                      <option value="price-asc">Price, low to high</option>
                      <option value="price-desc">Price, high to low</option>
                    </select>
                  </div>
                </div> */}
              </div>
              {/* Shop Top Area End  */}

              {/* Shop Bottom Area Start  */}
              <div className="shop-bottom-area">
                <div className="row">
                  {products.map((product) => (
                    <div
                      className="col-lg-4 col-xl-3 col-md-6 col-sm-6 col-xs-6"
                      data-aos="fade-up"
                      data-aos-delay="200"
                      key={product.id}
                    >
                      <div className="product mb-25px">
                        <div className="thumb">
                          <Link
                            to="#"
                            className="image"
                            onClick={() => handleProductClick(product.id)} // Navigate on product click
                          >
                            <img src={product.image} alt={product.name} />
                            <img
                              className="hover-image"
                              src={product.image}
                              alt={product.name}
                            />
                          </Link>
                          <span className="badges">
                            {product.off && (
                              <span className="sale">{product.off}% off</span>
                            )}
                            <span className="new">{product.badge}</span>
                          </span>
                        </div>
                        <div className="content">
                          <h5 className="title">
                            <Link
                              to="#"
                              onClick={() => handleProductClick(product.id)} // Navigate on product click
                            >
                              {product.name}
                            </Link>
                          </h5>
                          <span className="price">
                            {IsToken() ? (
                              <span className="new">₹{product.price}</span>
                            ) : (
                              ""
                            )}
                          </span>
                        </div>
                      </div>
                    </div>
                  ))}
                </div>
              </div>
              {/* Shop Bottom Area End  */}
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
