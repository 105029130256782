import React, { useEffect, useState, useRef } from "react";
import { Link, useParams, useOutletContext } from "react-router-dom";
import { GetApiHandlerWithoutParams, PostApiHandler } from "../utils/Api";
import LocationDateFilterModal from "../Components/Modal/LocationDateFilterModal";
import p1 from "../assets/images/product-image/1.jpg";
import Swiper from "swiper/bundle";
import "swiper/css/bundle";
import { Circles } from 'react-loader-spinner';
import CustomToast from "../Components/CustomToast";

export default function SingleProduct() {
  const { id } = useParams(); // Get the inventory id from the URL
  const { fetchCartItems, locationAndDates, setLocationAndDates } = useOutletContext();
  const [product, setProduct] = useState(null); // State to store the product data
  const [quantity, setQuantity] = useState(1); // State to handle the quantity input
  const [isInCart, setIsInCart] = useState(null); // State to track if the product is already in the cart (null by default)
  const [loading, setLoading] = useState(true); // Loading state to prevent rendering until data is fetched
  const [showLocationDateModal, setShowLocationDateModal] = useState(false);

  const swiperRefs = useRef(null);

  const openLocationDateModal = () => {
    setShowLocationDateModal(true); // Show the modal
  };
  
  const closeLocationDateModal = () => {
    setShowLocationDateModal(false); // Hide the modal
  };

  useEffect(() => {
    if (id) {
      fetchProductAndCartData(id);
  
      if (!locationAndDates || !locationAndDates.locationId || !locationAndDates.startDate || !locationAndDates.endDate) {
        openLocationDateModal();
      }
    }
  }, [id, locationAndDates]);

  const fetchProductAndCartData = async (inventoryId) => {
    setLoading(true); // Start loading

    try {
      // Fetch the cart first
      const cartEndPoint = `carts`;
      const cartResult = await GetApiHandlerWithoutParams(cartEndPoint, "");

      let cartItems = [];

      // Access cart directly as it's an array, not an object with a 'data' field
      if (cartResult && cartResult.success && Array.isArray(cartResult.cart)) {
        // Extract product IDs from cart items
        cartItems = cartResult.cart.map((item) => item.product.id);
      }

      // Fetch the product details
      const productEndPoint = `inventories/${inventoryId}`;
      const productResult = await GetApiHandlerWithoutParams(productEndPoint, "");

      if (productResult && productResult.success && productResult.inventory && productResult.inventory.product) {
        const fetchedProduct = {
          id: productResult.inventory.product.id,
          name: productResult.inventory.product.product_name,
          price: productResult.inventory.product.rental_price,
          image: productResult.inventory.product.image_1 || p1,
          location: productResult.inventory.location.name,
          locationId: productResult.inventory.location.id,
          description: productResult.inventory.product.description,
          quantityAvailable: productResult.inventory.quantity_available,
        };
        setProduct(fetchedProduct); // Set the product data

        // Check if the product is already in the cart
        if (cartItems.includes(fetchedProduct.id)) {
          setIsInCart(true); // Product is in the cart
        } else {
          setIsInCart(false); // Product is not in the cart
        }
      }
    } catch (error) {
      console.error("Failed to fetch product or cart details:", error.message);
    } finally {
      setLoading(false); // End loading
    }
  };

  const handleAddToCart = async () => {
    if (quantity > product.quantityAvailable) {
      // alert(`Cannot add more than the available quantity (${product.quantityAvailable})`);
      CustomToast(`Cannot add more than the available quantity: ${product.quantityAvailable}`, "warning");
      return;
    }
    
    try {
      const body = {
        product_id: product.id,
        quantity: quantity,
        location_id: locationAndDates.locationId,
        rentalstartdate:locationAndDates.startDate,
        rentalenddate:locationAndDates.endDate,
      };
      console.log(body);
      const result = await PostApiHandler(body, "carts");
      if (result.success) {
        // console.log("Product added to cart successfully");
        CustomToast("Product added to cart successfully","success")
        setIsInCart(true); // Mark the product as in the cart after adding it
        await fetchCartItems(); // Refresh cart items in the header
      } else {
        // console.error("Failed to add product to cart:", result.message);
        CustomToast(`Failed to add product to cart: ${result.message}`, "error");
      }
    } catch (error) {
      // console.error("Failed to add product to cart:", error.message);
      CustomToast(`Failed to add product to cart: ${error.message}`, "error");
    }
  };

  const handleQuantityChange = (newQuantity) => {
    if (newQuantity > product.quantityAvailable) {
      // alert(`Only ${product.quantityAvailable} items are available.`);
      CustomToast(`Only ${product.quantityAvailable} items are available.`,"warning")
      setQuantity(product.quantityAvailable); // Set to max available quantity
    } else if (newQuantity < 1) {
      setQuantity(1); // Ensure quantity does not go below 1
    } else {
      setQuantity(newQuantity);
    }
  };

  const incrementQuantity = () => {
    handleQuantityChange(quantity + 1);
  };

  const decrementQuantity = () => {
    handleQuantityChange(quantity - 1);
  };

  // Handle location and date submission from the modal
  const handleLocationDateSubmit = (formData) => {
    setLocationAndDates(formData);
    localStorage.setItem("locationDateFilter", JSON.stringify(formData));
    closeLocationDateModal();
  };

  // Initialize Swiper only after the product is loaded and DOM elements are ready
  useEffect(() => {
    if (!loading && product) {
      swiperRefs.current = new Swiper(".product-image-slider", {
        slidesPerView: 4,
        spaceBetween: 0,
        loop: true,
        autoplay: false,
        pagination: {
          el: ".swiper-pagination",
          clickable: true,
        },
        navigation: {
          nextEl: ".swiper-button-next",
          prevEl: ".swiper-button-prev",
        },
      });
    }

    // Cleanup on unmount
    return () => {
      if (swiperRefs.current) {
        swiperRefs.current.destroy();
      }
    };
  }, [loading, product]);

  if (loading) {
    return (
      <div className="loading-container">
        <Circles
          height="100"
          width="100"
          color="#ff7004"
          ariaLabel="loading"
          wrapperStyle={{ display: "flex", justifyContent: "center", alignItems: "center", height: "100vh" }}
        />
      </div>
    );
  }

  return (
    <>
      {/* Product Details Area Start */}
      <div className="product-details-area pt-100px pb-100px">
        <div className="container">
          <div className="row">
            <div className="col-lg-5 col-sm-12 col-xs-12 mb-lm-30px mb-md-30px mb-sm-30px">
              <div className="swiper-container zoom-top">
                <div className="swiper-wrapper">
                  <div className="swiper-slide zoom-image-hover">
                    <img
                      className="img-responsive m-auto"
                      src={product?.image || p1}
                      alt={product?.name || "Product Image"}
                    />
                  </div>
                </div>
              </div>
              <div className="product-image-slider swiper-container zoom-thumbs slider-nav-style-1 small-nav mt-15px mb-15px">
                <div className="swiper-wrapper">
                  <div className="swiper-slide">
                    <img
                      className="img-responsive m-auto"
                      src={product?.image || p1}
                      alt={product?.name || "Product Image"}
                    />
                  </div>
                </div>

                <div className="swiper-buttons">
                  <div className="swiper-button-next"></div>
                  <div className="swiper-button-prev"></div>
                </div>
              </div>
            </div>
            <div
              className="col-lg-7 col-sm-12 col-xs-12"
              data-aos="fade-up"
              data-aos-delay="200"
            >
              <div className="product-details-content quickview-content">
                <h2>{product?.name || "Product Name"}</h2>
                <p className="reference">Available Quantity:<span> {product?.quantityAvailable}</span></p>
                <div className="pricing-meta">
                  <ul>
                    <li className="old-price not-cut">
                      ₹{product?.price || 0}
                    </li>
                  </ul>
                </div>
                <p className="quickview-para">
                {product?.description?.replace(/<\/?[^>]+(>|$)/g, "") || ""}
                </p>
                {isInCart === false ? (
                  <div className="pro-details-quality">
                    <div className="cart-plus-minus">
                      <button className="dec qtybutton" onClick={decrementQuantity}>-</button>
                      <input
                        className="cart-plus-minus-box"
                        type="text"
                        name="qtybutton"
                        value={quantity}
                        onChange={(e) => handleQuantityChange(parseInt(e.target.value))}
                        min="1"
                      />
                      <button className="inc qtybutton" onClick={incrementQuantity}>+</button>
                    </div>
                    <div className="pro-details-cart">
                      <button
                        className="add-cart btn btn-primary btn-hover-primary ml-4"
                        onClick={handleAddToCart}
                      >
                        Add To Cart
                      </button>
                    </div>
                  </div>
                ) : isInCart === true ? (
                  <div className="cart-message">
                    <p>
                      This product is already in your cart. You can update the
                      quantity in your <Link to="/cart">cart</Link>.
                    </p>
                  </div>
                ) : (
                  <div>Loading...</div>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>


       {/* Product Details Description Area Start */}
       <div
        className="description-review-area pb-100px"
        data-aos="fade-up"
        data-aos-delay="200"
      >
        <div className="container">
          <div className="description-review-wrapper">
            <div className="description-review-topbar nav">
              <Link data-bs-toggle="tab" to="#des-details1">
                Description
              </Link>
              <Link className="active" data-bs-toggle="tab" to="#des-details2">
                Product Details
              </Link>
            </div>
            <div className="tab-content description-review-bottom">
              <div id="des-details2" className="tab-pane active">
                <div className="product-anotherinfo-wrapper">
                  <ul>
                    <li>
                      <span>Location</span> {product?.location || "Unknown"}
                    </li>
                    
                  
                 
                  </ul>
                </div>
              </div>
              <div id="des-details1" className="tab-pane">
                <div className="product-description-wrapper">
                  <p>{product?.description?.replace(/<\/?[^>]+(>|$)/g, "") || "No description available."}</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* Product Details Description Area End */}


      {/* Location Date Filter Modal */}
      {showLocationDateModal && (
        <LocationDateFilterModal
          show={showLocationDateModal}
          onClose={closeLocationDateModal}
          onSubmit={handleLocationDateSubmit}
        />
      )}
    </>
  );
}
