import React, { useState, useEffect } from "react";
import { Link, useNavigate, useOutletContext } from "react-router-dom";
import { GetApiHandlerWithoutParams, PostApiHandler } from "../utils/Api";
import p1 from "../assets/images/product-image/1.jpg";
import Emptycart from "../Components/Cart/Emptycart";
import CustomToast from "../Components/CustomToast";
import { toast } from 'react-toastify'; // Import toast for handling manual close

export default function Cart() {
  const [products, setProducts] = useState([]);
  const [updatedQuantities, setUpdatedQuantities] = useState({});
  const navigate = useNavigate();
  const { fetchCartItems } = useOutletContext(); // Fetch this from the parent component (Layout)

  // Function to calculate the subtotal for each item
  const calculateSubtotal = (price, quantity) => {
    return (price * quantity).toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  };

  // Function to calculate the grand total for the cart
  const calculateGrandTotal = () => {
    return products
      .reduce((acc, item) => acc + parseFloat(item.price * (updatedQuantities[item.id] || item.quantity)), 0)
      .toFixed(2)
      .toString()
      .replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  };

  const confirmClearCart = () => {
    // Use toast notification for confirmation
    toast(
      ({ closeToast }) => (
        <div>
          <p>Are you sure you want to clear your cart?</p>
          <div className="d-flex justify-content-between">
            <button
              className="btn btn-success btn-sm"
              onClick={() => {
                handleClearCart();
                closeToast(); // Close toast on confirmation
              }}
            >
              Yes
            </button>
            <button
              className="btn btn-danger btn-sm"
              onClick={() => closeToast()} // Close toast if cancelled
            >
              No
            </button>
          </div>
        </div>
      ),
      {
        autoClose: false, // Disable auto close to allow user interaction
        closeOnClick: false, // Prevent toast from closing on click outside
        position: "top-center",
      }
    );
  };
  
  const handleClearCart = async () => {
    try {
      const result = await PostApiHandler({}, `carts/delete-all`);
      if (result.success) {
        setProducts([]); // Clear the products list on success
        fetchCartItems(); // Fetch the updated cart count for the header
        CustomToast("Your cart has been cleared.", "info");
      } else {
        console.error("Failed to clear cart:", result.message); // Log the failure message
      }
    } catch (error) {
      console.error("Failed to clear cart:", error.message); // Catch any API call errors
    }
  };
  

  const handleQuantityChange = (id, quantity) => {
    setUpdatedQuantities((prev) => ({
      ...prev,
      [id]: quantity,
    }));
  };

  const confirmUpdateCart = () => {
    // Use toast notification for confirmation
    toast(
      ({ closeToast }) => (
        <div>
          <p>Do you want to update the cart with the new quantities?</p>
          <div className="d-flex justify-content-between">
            <button
              className="btn btn-success btn-sm"
              onClick={() => {
                handleUpdateCart();
                closeToast(); // Close toast on confirmation
              }}
            >
              Yes
            </button>
            <button
              className="btn btn-danger btn-sm"
              onClick={() => closeToast()} // Close toast if cancelled
            >
              No
            </button>
          </div>
        </div>
      ),
      {
        autoClose: false, // Disable auto close to allow user interaction
        closeOnClick: false, // Prevent toast from closing on click outside
        position: "top-center",
      }
    );
  };

  const handleUpdateCart = async () => {
    const updates = Object.keys(updatedQuantities).map(async (id) => {
      const quantity = updatedQuantities[id];
      try {
        const result = await PostApiHandler({ quantity }, `carts/update/${id}`);
        if (!result.success) {
          throw new Error(result.message);
        }
      } catch (error) {
        console.error(`Failed to update cart for item ${id}:`, error.message);
      }
    });

    try {
      await Promise.all(updates);
      fetchCartItems(); // Refresh the cart items
      CustomToast("Cart updated successfully.", "success");
    } catch (error) {
      console.error("Failed to update the cart:", error);
    }
  };

  const removeFromCart = async (id) => {
    try {
      const result = await PostApiHandler({}, `carts/delete/${id}`);
      if (result.success) {
        setProducts(products.filter((item) => item.id !== id));
        fetchCartItems(); // Fetch the updated cart count for the header
      }
    } catch (error) {
      console.error("Failed to remove item from cart:", error.message);
    }
  };

  // Fetch Cart Data API
  useEffect(() => {
    cartApiCall();
  }, []);

  const cartApiCall = async () => {
    try {
      const endPoint = `carts`;
      const result = await GetApiHandlerWithoutParams(endPoint, "");
      if (result && result.success) {
        const fetchedProducts = result.cart.map((cart) => ({
          id: cart.id,
          name: cart.product.product_name,
          price: cart.product.rental_price,
          totalAmount: cart.product.total_amount,
          quantity: cart.quantity, // Ensure quantity is set
          image: cart.product.image_1 || p1,
          badge: cart.location ? cart.location.name : "", // Handle location badge safely
          off: cart.product.discount_percentage,
          inventoryid: cart.inventory.id,
          link: `/product/${cart.inventory.id}`,
          quantityAvailable: cart.inventory.quantity_available,
        }));

        setProducts(fetchedProducts);
      }
    } catch (err) {
      console.error("Failed to fetch Cart items:", err.message);
    }
  };

  if (!products.length) {
    return <Emptycart />;
  }

  return (
    <>
      {/* Cart Area Start */}
      <div className="cart-main-area pt-100px pb-100px">
        <div className="container">
          <h3 className="cart-page-title">Your cart items</h3>
          <div className="row">
            <div className="col-lg-12 col-md-12 col-sm-12 col-12">
              <div className="table-content table-responsive cart-table-content">
                <table>
                  <thead>
                    <tr>
                      <th></th>
                      <th>Product Name</th>
                      <th>Unit Price</th>
                      <th>Qty</th>
                      <th>Subtotal</th>
                      <th>Action</th>
                    </tr>
                  </thead>
                  <tbody>
                    {products.map((item) => (
                      <tr key={item.id}>
                        <td className="product-thumbnail">
                          <Link to={`/product/${item.inventoryid}`}>
                            <img className="img-responsive ml-15px" src={item.image} alt={item.name} />
                          </Link>
                        </td>
                        <td className="product-name">
                          <Link to={`/product/${item.inventoryid}`}>{item.name}</Link>
                        </td>
                        <td className="product-price-cart">
                          <span className="amount">₹{item.price}</span>
                        </td>
                        <td className="product-quantity">
                          <div className="cart-plus-minus">
                            <button className="dec qtybutton" onClick={() => handleQuantityChange(item.id, (updatedQuantities[item.id] || item.quantity) - 1)}>-</button>
                            <input
                              className="cart-plus-minus-box"
                              type="text"
                              name="qtybutton"
                              value={updatedQuantities[item.id] || item.quantity}
                              onChange={(e) => handleQuantityChange(item.id, parseInt(e.target.value))}
                              min="1"
                            />
                            <button className="inc qtybutton" onClick={() => handleQuantityChange(item.id, (updatedQuantities[item.id] || item.quantity) + 1)}>+</button>
                          </div>
                        </td>
                        <td className="product-subtotal">
                          ₹{calculateSubtotal(item.price, updatedQuantities[item.id] || item.quantity)}
                        </td>
                        <td className="product-remove">
                          <button onClick={() => removeFromCart(item.id)}>
                            <i className="icon-trash"></i>
                          </button>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
              <div className="row">
                <div className="col-lg-12">
                  <div className="cart-shiping-update-wrapper">
                    <div className="cart-shiping-update">
                      <Link to="/shop">Continue Shopping</Link>
                    </div>
                    <div className="cart-clear">
                      <button onClick={confirmUpdateCart}>Update Shopping Cart</button>
                      <Link onClick={confirmClearCart}>Clear Shopping Cart</Link>
                    </div>
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-lg-4 col-md-6 mb-lm-30px"></div>
                <div className="col-lg-4 col-md-6 mb-lm-30px"></div>
                <div className="col-lg-4 col-md-12 mt-md-30px">
                  <div className="grand-totall">
                    <div className="title-wrap">
                      <h4 className="cart-bottom-title section-bg-gary-cart">Cart Total</h4>
                    </div>
                    <h5>
                      Total products <span>₹{calculateGrandTotal()}</span>
                    </h5>
                    <h4 className="grand-totall-title">
                      Grand Total <span>₹{calculateGrandTotal()}</span>
                    </h4>
                    <Link to="/checkout">Proceed to Checkout</Link>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* Cart Area End */}
    </>
  );
}
