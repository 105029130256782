import { useState } from "react";
import { RegisterPostApiHandler } from "../utils/Api";
import { useNavigate } from "react-router-dom";

export default function Register() {
  const [email, setEmail] = useState("");
  const [name, setName] = useState("");
  const [phonenumber, setPhonenumber] = useState("");
  const [companyname, setcompanyname] = useState("");
  const [companyaddress, setcompanyaddress] = useState("");
  const [city, setcity] = useState("");
  const [pincode, setpincode] = useState("");
  const [gstnumber, setgstnumber] = useState("");
  const [sizeofeventcompany, setsizeofeventcompany] = useState("");
  const [state, setstate] = useState("");

  const [errorMessage, setErrorMessage] = useState({
    email: "",
    name: "",
    phonenumber: "",
    companyname: "",
    companyaddress: "",
    city: "",
    pincode: "",
    gstnumber: "",
    sizeofeventcompany: "",
    state: "",
    register: "",
  });
  const [isSubmitting, setIsSubmitting] = useState(false);
  const navigateTo = useNavigate();

  // Handle changes for all inputs
  const handleChange = (e, setter, field) => {
    setter(e.target.value);
    setErrorMessage((prev) => ({ ...prev, [field]: "" }));
  };

  const handleFormSubmit = async (e) => {
    e.preventDefault();
    setIsSubmitting(true);

    // Validate required fields
    if (!phonenumber || !email || !name || !companyname || !companyaddress || !city || !pincode) {
      setErrorMessage((prev) => ({
        ...prev,
        phonenumber: phonenumber ? "" : "This phone field is required.",
        email: email ? "" : "This email field is required.",
        name: name ? "" : "This name field is required.",
        companyname: companyname ? "" : "Company name is required.",
        companyaddress: companyaddress ? "" : "Company address is required.",
        city: city ? "" : "City is required.",
        pincode: pincode ? "" : "Pincode is required.",
        state: state ? "":"State is required.",
      }));
      setIsSubmitting(false);
      return;
    }

    setErrorMessage({});
    
    const formData = new FormData();
    formData.append('name', name);
    formData.append('mobile', phonenumber);
    formData.append('email', email);
    formData.append('usertype', 'event manager');
    formData.append('company_name', companyname);
    formData.append('company_address', companyaddress);
    formData.append('city', city);
    formData.append('pincode', pincode);
    formData.append('state', state);
    formData.append('gst_number', gstnumber || "");  // Optional
    formData.append('size_of_event_company', sizeofeventcompany);

    try {
      const response = await RegisterPostApiHandler(formData, 'register');
      if (response.success) {
        navigateTo('/login');
      } else {
        setErrorMessage((prev) => ({ ...prev, register: response.message }));
      }
    } catch (error) {
      setErrorMessage((prev) => ({ ...prev, register: "Something went wrong, please try again later." }));
    } finally {
      setIsSubmitting(false);
    }
  };

  return (
    <>
      <div className="login-register-area pt-100px pb-100px">
        <div className="container">
          <div className="row">
            <div className="col-lg-3"></div>
            <div className="col-lg-6 col-md-12 ml-auto mr-auto">
              <div className="login-register-wrapper">
                <div className="login-register-tab-list nav">
                  <a className="active" data-bs-toggle="tab" href="#lg1">
                    <h4>Register</h4>
                  </a>
                </div>
                <div className="tab-content">
                  <div id="lg1" className="tab-pane active">
                    <div className="login-form-container">
                      <div className="login-register-form">
                        <form onSubmit={handleFormSubmit}>
                          <input
                            type="text"
                            placeholder="Name"
                            onChange={(e) => handleChange(e, setName, 'name')}
                            value={name}
                            disabled={isSubmitting}
                          />
                          {errorMessage.name && <span className="error">{errorMessage.name}</span>}
                          
                          <input
                            type="text"
                            placeholder="Mobile Number"
                            onChange={(e) => handleChange(e, setPhonenumber, 'phonenumber')}
                            value={phonenumber}
                            disabled={isSubmitting}
                          />
                          {errorMessage.phonenumber && <span className="error">{errorMessage.phonenumber}</span>}
                          
                          <input
                            type="email"
                            placeholder="Email"
                            onChange={(e) => handleChange(e, setEmail, 'email')}
                            value={email}
                            disabled={isSubmitting}
                          />
                          {errorMessage.email && <span className="error">{errorMessage.email}</span>}
                          
                          <input
                            type="text"
                            placeholder="Company Name"
                            onChange={(e) => handleChange(e, setcompanyname, 'companyname')}
                            value={companyname}
                            disabled={isSubmitting}
                          />
                          {errorMessage.companyname && <span className="error">{errorMessage.companyname}</span>}
                          
                          <input
                            type="text"
                            placeholder="Company Address"
                            onChange={(e) => handleChange(e, setcompanyaddress, 'companyaddress')}
                            value={companyaddress}
                            disabled={isSubmitting}
                          />
                          {errorMessage.companyaddress && <span className="error">{errorMessage.companyaddress}</span>}
                          
                          <input
                            type="text"
                            placeholder="City"
                            onChange={(e) => handleChange(e, setcity, 'city')}
                            value={city}
                            disabled={isSubmitting}
                          />
                          {errorMessage.city && <span className="error">{errorMessage.city}</span>}
                          
                          <input
                            type="text"
                            placeholder="Pincode"
                            onChange={(e) => handleChange(e, setpincode, 'pincode')}
                            value={pincode}
                            disabled={isSubmitting}
                          />
                          {errorMessage.pincode && <span className="error">{errorMessage.pincode}</span>}
                          
                          <input
                            type="text"
                            placeholder="Gst Number"
                            onChange={(e) => handleChange(e, setgstnumber, 'gstnumber')}
                            value={gstnumber}
                            disabled={isSubmitting}
                          />
                          
                          <select
                            className="form-control"
                            value={sizeofeventcompany}
                            onChange={(e) => handleChange(e, setsizeofeventcompany, 'sizeofeventcompany')}
                            style={{ marginBottom: 30, borderRadius: "0px", borderColor: "#ebebeb" }}
                          >
                            <option value="">Select Size of the company</option>
                            <option value="small">Small</option>
                            <option value="medium">Medium</option>
                            <option value="large">Large</option>
                          </select>
                          
                          <input
                            type="text"
                            placeholder="State"
                            onChange={(e) => handleChange(e, setstate, 'state')}
                            value={state}
                            disabled={isSubmitting}
                            
                          />

                          <div className="button-box">
                            <button
                              className="login-button w-100"
                              type="submit"
                              disabled={isSubmitting}
                            >
                              <span>{isSubmitting ? 'Registering...' : 'Register'}</span>
                            </button>
                          </div>
                        </form>

                        {errorMessage.register && <span className="error">{errorMessage.register}</span>}

                        <form>
                          <div className="row" style={{ marginTop: "20px" }}>
                            <div className="col-md-12">
                              <div className="button-box">
                                <button
                                  className="forget-password-button w-100"
                                  type="submit"
                                  onClick={() => navigateTo('/login')}
                                >
                                  <span>Already have an account?</span>
                                </button>
                              </div>
                            </div>
                          </div>
                        </form>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-3"></div>
          </div>
        </div>
      </div>
    </>
  );
}
