import React, { useEffect, useRef } from "react";
import { Link } from "react-router-dom";
// import Swiper from 'swiper';
import slider1 from "../../assets/images/slider-image/slider-1.png";
import slider2 from "../../assets/images/slider-image/slider-2.png";
import Swiper from "swiper/bundle";
import "swiper/css/bundle";

const HeroSlider = () => {
  const swiperRef = useRef(null);

  useEffect(() => {
    swiperRef.current = new Swiper(".hero-slider", {
      slidesPerView: 1,
      loop: true,
      speed: 1000,
      effect: "fade",
      autoplay: {
        delay: 7000,
        disableOnInteraction: false,
      },
      pagination: {
        el: ".swiper-pagination",
        clickable: true,
      },
      navigation: {
        nextEl: ".swiper-button-next",
        prevEl: ".swiper-button-prev",
      },
    });

    // Cleanup Swiper instance on component unmount
    return () => {
      if (swiperRef.current && swiperRef.current.destroy) {
        swiperRef.current.destroy(true, true);
      }
    };
  }, []);

  return (
    <div className="section">
      <div className="hero-slider swiper-container slider-nav-style-1 slider-dot-style-1">
        <div className="swiper-wrapper">
          {/* Slide 1 */}
          <div className="hero-slide-item slider-height swiper-slide d-flex">
            <div className="container align-self-center">
              <div className="row">
                <div className="col-xl-6 col-lg-7 col-md-7 col-sm-7 align-self-center">
                  <div className="hero-slide-content slider-animated-1">
                    <span className="category">New Products</span>
                    <h2 className="title-1">Flexible Chair</h2>
                    <p>
                    Discover the perfect blend of comfort and versatility with our brand-new Flexible Chair.
                    </p>
                    <Link
                      to="/"
                      className="btn btn-lg btn-primary btn-hover-dark mt-5"
                    >
                      Shop Now
                    </Link>
                  </div>
                </div>
                <div className="col-xl-6 col-lg-5 col-md-5 col-sm-5">
                  <div className="hero-slide-image">
                    <img src={slider1} alt="Flexible Chair" />
                  </div>
                </div>
              </div>
            </div>
          </div>

          {/* Slide 2 */}
          <div className="hero-slide-item slider-height swiper-slide d-flex">
            <div className="container align-self-center">
              <div className="row">
                <div className="col-xl-6 col-lg-7 col-md-7 col-sm-7 align-self-center">
                  <div className="hero-slide-content slider-animated-1">
                    <span className="category">New Products</span>
                    <h2 className="title-1">Flexible Chair</h2>
                    <p>
                    Elevate your space with a chair that not only complements your decor but also enhances your everyday comfort.
                    </p>
                    <Link
                      to="/"
                      className="btn btn-lg btn-primary btn-hover-dark mt-5"
                    >
                      Shop Now
                    </Link>
                  </div>
                </div>
                <div className="col-xl-6 col-lg-5 col-md-5 col-sm-5">
                  <div className="hero-slide-image">
                    <img src={slider2} alt="Flexible Chair" />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        {/* Pagination */}
        <div className="swiper-pagination swiper-pagination-white"></div>

        {/* Navigation Buttons */}
        <div className="swiper-buttons">
          <div className="swiper-button-next"></div>
          <div className="swiper-button-prev"></div>
        </div>
      </div>
    </div>
  );
};

export default HeroSlider;
